import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Col,
    Form,
    message,
    Row,
    Select,
    Space,
    Typography,
} from 'antd'
import { AccountContext } from '../../../../../utils/Auth'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useLocalDb } from '../../../../../utils/useLocalDb'
import usePWAInstall from '../../../../../utils/usePWAInstall'
import NovoComponenteInspecaoDrawer from './NovoComponenteInspecaoDrawer'
import { MinusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import { useDebounce } from 'use-debounce'
import VisualizarComponenteDrawer from '../../../../Componentes/VisualizarComponenteDrawer'

type ComponenteSelectProps = {
    tipoComponente: 'painel' | 'inversor' | 'transformador'
    formInstance: any
    formType: string
}
const ComponenteSelect = (props: ComponenteSelectProps) => {
    const [novoComponenteDrawerOpen, setNovoComponenteDrawerOpen] =
        useState(false)
    const [fabricantes, setFabricantes] = useState<string[]>([])
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [modelos, setModelos] = useState<string[]>([])
    const [modelo, setModelo] = useState<string>('')
    const [modeloDebounce] = useDebounce(modelo, 500)
    const [messageApi, contextHolder] = message.useMessage()
    const [fabricanteFields, setFabricanteFields] = useState<any[]>([])
    const [visualizarComponenteDrawerOpen, setVisualizarComponenteDrawerOpen] =
        useState(false)
    const [
        visualizarComponenteDrawerModelo,
        setVisualizarComponenteDrawerModelo,
    ] = useState('')
    const { authenticatedFetch } = useContext(AccountContext)
    const navigate = useNavigate()
    const { id, idInspecao } = useParams()
    const { getFabricantesFromLocalDb, getComponentesFromLocalDb, isOnline } =
        useLocalDb()
    const { isInstalled } = usePWAInstall()
    const location = useLocation()

    useEffect(() => {
        if (!location.pathname.includes('novoComponente')) {
            getFabricantes()
        }
    }, [props.tipoComponente, location.pathname, isInstalled])

    useEffect(() => {
        getModelos(currentIndex)
    }, [currentIndex, modeloDebounce])

    const getFabricantes = () => {
        if (props.tipoComponente) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    '/componentes/' +
                    props.tipoComponente +
                    '/fabricantes',
                'GET'
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setFabricantes(data)
                        })
                    }
                })
                .catch(() => {
                    if (isInstalled) {
                        getFabricantesFromLocalDb(props.tipoComponente).then(
                            (data) => setFabricantes(data)
                        )
                    } else if (isOnline) {
                        messageApi.error({
                            key: 'componentesFetchError',
                            content: 'Erro ao tentar contactar o servidor',
                            duration: 8,
                        })
                    }
                })
        }
    }

    const getModelos = async (currentIndex: number) => {
        let requestUrl =
            process.env.REACT_APP_SERVER_IP +
            '/componentes/' +
            props.tipoComponente +
            `/listar?pagina=0` +
            `&tamanhoPagina=1000`

        if (
            currentIndex >= 0 &&
            fabricanteFields[currentIndex] &&
            fabricanteFields[currentIndex].length > 0
        ) {
            requestUrl += `&fabricante=${fabricanteFields[currentIndex]}`
        }

        if (modeloDebounce.length > 0) {
            requestUrl += `&modelo=${modeloDebounce}`
        }

        authenticatedFetch(requestUrl, 'GET')
            .then((res: { ok: any; json: () => Promise<any[]> }) => {
                if (res.ok) {
                    res.json().then((data: any) => {
                        let modelos = data.content.map(
                            (componente: any) => componente.modelo
                        )
                        setModelos(modelos)
                    })
                }
            })
            .catch((e: any) => {
                if (isInstalled && !isOnline) {
                    getComponentesFromLocalDb(
                        props.tipoComponente,
                        '',
                        undefined
                    ).then((data) => {
                        const modelos = data.map(
                            (componente) => componente.modelo
                        )
                        setModelos(modelos)
                    })
                } else if (isOnline) {
                    message.error({
                        key: 'componentesFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
                }
            })
    }

    const getFormFieldName = () => {
        switch (props.tipoComponente) {
            case 'painel':
                return 'paineis'
                break
            case 'inversor':
                return 'inversores'
                break
            case 'transformador':
                return 'transformadores'
                break
            default:
                return ''
                break
        }
    }

    const modelosForm = Form.useWatch(getFormFieldName(), props.formInstance)

    const showComponenteDrawer = (idx: any) => {
        const modelo = props.formInstance.getFieldValue(getFormFieldName())[idx]
        if (!modelo) {
            return
        }
        setVisualizarComponenteDrawerModelo(modelo)
        setVisualizarComponenteDrawerOpen(true)
    }

    return (
        <>
            <VisualizarComponenteDrawer
                open={visualizarComponenteDrawerOpen}
                setOpen={setVisualizarComponenteDrawerOpen}
                id={-1}
                modelo={visualizarComponenteDrawerModelo}
                tipoComponente={props.tipoComponente}
                setComponenteModificado={() => {}}
                readOnlyMode={true}
            />
            <Form.List name={getFormFieldName()}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div key={key}>
                                <Space>
                                    <b>Modelo {index + 1}</b>
                                    {props.tipoComponente ===
                                        'transformador' && (
                                        <Typography.Text type={'secondary'}>
                                            {' '}
                                            (opcional)
                                        </Typography.Text>
                                    )}
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    ) : null}
                                </Space>
                                <Row gutter={16}>
                                    <Col
                                        xs={{ span: 24 }}
                                        lg={{ span: 11 }}
                                        xl={{ span: 11 }}
                                    >
                                        <Select
                                            value={fabricanteFields[index]}
                                            onChange={(value) => {
                                                let newFabricanteField = [
                                                    ...fabricanteFields,
                                                ]
                                                newFabricanteField[index] =
                                                    value
                                                setFabricanteFields(
                                                    newFabricanteField
                                                )
                                            }}
                                            allowClear={true}
                                            style={{
                                                width: '100%',
                                                marginBottom: 16,
                                            }}
                                            showSearch
                                            placeholder="Fabricante"
                                            options={fabricantes.map(
                                                (fabricante) => ({
                                                    label: fabricante,
                                                    value: fabricante,
                                                })
                                            )}
                                        />
                                    </Col>
                                    <Col
                                        xs={{
                                            span: 21,
                                        }}
                                        lg={{ span: 11 }}
                                        xl={{ span: 11 }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={name}
                                            rules={
                                                props.tipoComponente !==
                                                'transformador'
                                                    ? [
                                                          {
                                                              required: true,
                                                              message:
                                                                  'Por favor selecione um modelo',
                                                          },
                                                      ]
                                                    : []
                                            }
                                        >
                                            <Select
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                                onSearch={(e) => {
                                                    setModelo(e)
                                                }}
                                                onFocus={(e) => {
                                                    setCurrentIndex(index)
                                                    getModelos(index)
                                                }}
                                                showSearch
                                                placeholder="Modelo"
                                                options={modelos.map(
                                                    (modelo) => ({
                                                        label: modelo,
                                                        value: modelo,
                                                    })
                                                )}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 3 }}
                                        lg={{ span: 1 }}
                                        xl={{ span: 1 }}
                                    >
                                        <Button
                                            disabled={
                                                modelosForm &&
                                                modelosForm[index] === undefined
                                            }
                                            style={{
                                                minWidth: 32,
                                                width: '100%',
                                            }}
                                            onClick={(e) => {
                                                showComponenteDrawer(name)
                                                navigate('visualizarComponente')
                                            }}
                                            icon={<SearchOutlined />}
                                        />
                                    </Col>
                                </Row>

                                {index === fields.length - 1 && (
                                    <Row gutter={16}>
                                        <Col
                                            xs={{ span: 24, offset: 0 }}
                                            lg={{ span: 12, offset: 12 }}
                                            xl={{ span: 12, offset: 12 }}
                                        >
                                            <Button
                                                type={'primary'}
                                                style={{
                                                    width: '100%',
                                                    marginTop: -8,
                                                }}
                                                onClick={(e) => {
                                                    if (idInspecao) {
                                                        navigate(
                                                            '/app/usinas/' +
                                                                id +
                                                                '/inspecao/' +
                                                                idInspecao +
                                                                '/modificar/novoComponente'
                                                        )
                                                    } else {
                                                        navigate(
                                                            '/app/usinas/' +
                                                                id +
                                                                '/inspecao/nova/novoComponente'
                                                        )
                                                    }
                                                    setNovoComponenteDrawerOpen(
                                                        true
                                                    )
                                                }}
                                            >
                                                Não encontrei o modelo
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                        ))}
                        <Row>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <Button
                                    type={'dashed'}
                                    style={{ width: '100%', marginTop: 16 }}
                                    onClick={() => add()}
                                >
                                    Adicionar mais um modelo
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>

            <NovoComponenteInspecaoDrawer
                open={novoComponenteDrawerOpen}
                setOpen={setNovoComponenteDrawerOpen}
                tipoComponente={props.tipoComponente}
                formInstance={props.formInstance}
            />
            {contextHolder}
        </>
    )
}

export default ComponenteSelect
