import React from 'react'
import { Card } from 'antd'

const Dashboard = () => {
    return <Card title={'Dashboard'}></Card>
}

Dashboard.propTypes = {}

export default Dashboard
