import React, { useContext } from 'react'
import {
    Button,
    Checkbox,
    Col,
    Drawer,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Space,
} from 'antd'
import useWindowDimensions from '../../../../../utils/useWindowDimensions'
import { useNavigate } from 'react-router-dom'
import FabricanteWithAddField from '../../../../../components/FabricanteWithAddField'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import usePWAInstall from '../../../../../utils/usePWAInstall'
import { useLocalDb } from '../../../../../utils/useLocalDb'
import { AccountContext } from '../../../../../utils/Auth'

type PainelDrawerProps = {
    open: boolean
    setOpen: (open: boolean) => void
    tipoComponente: 'painel' | 'inversor' | 'transformador'
    formInstance: any
}

const NovoComponenteInspecaoDrawer = (props: PainelDrawerProps) => {
    const [messageApi, contextHolder] = message.useMessage()
    const [form] = Form.useForm()
    const { width } = useWindowDimensions()
    const navigate = useNavigate()
    const { authenticatedFetch } = useContext(AccountContext)
    const { isInstalled } = usePWAInstall()
    const { addComponenteToLocalDb } = useLocalDb()

    const onFinish = (values: any) => {
        let newValues = values
        delete newValues.tipo

        if (props.tipoComponente !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    '/componentes/' +
                    props.tipoComponente,
                'POST',
                JSON.stringify(newValues)
            )
                .then((res: { status: number }) => {
                    if (res.status === 201) {
                        messageApi.success(
                            'Componente cadastrado com sucesso!',
                            8
                        )

                        switch (props.tipoComponente) {
                            case 'painel':
                                updateFormField('paineis', newValues.modelo)
                                break
                            case 'inversor':
                                updateFormField('inversores', newValues.modelo)
                                break
                            case 'transformador':
                                updateFormField(
                                    'transformadores',
                                    newValues.modelo
                                )
                                break
                            default:
                                break
                        }

                        props.setOpen(false)
                        navigate(-1)
                    } else {
                        messageApi.error(
                            'Erro ao tentar cadastrar o componente',
                            8
                        )
                    }
                })
                .catch(() => {
                    if (isInstalled) {
                        addComponenteToLocalDb(
                            props.tipoComponente,
                            newValues
                        ).then(
                            messageApi.success(
                                'Componente cadastrado no banco de dados local com sucesso!',
                                8
                            )
                        )
                        navigate(-1)
                    } else {
                        messageApi.error(
                            'Erro ao tentar contactar o servidor',
                            8
                        )
                    }
                })
        }
    }

    const updateFormField = (field: string, value: any) => {
        let oldValue = form.getFieldValue(field)
        let newValue = oldValue

        if (oldValue === undefined) {
            newValue = [value]
        } else {
            newValue.push(value)
        }
        props.formInstance.setFieldValue(field, newValue)
    }

    const painelComponente = (
        <Row gutter={16}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Form.Item
                    name={'potencia'}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor entre com a potência',
                        },
                    ]}
                >
                    <InputNumber
                        style={{ width: '100%' }}
                        placeholder={'Potência'}
                        addonAfter={'Wp'}
                    />
                </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Form.Item name={'bifacial'} valuePropName="checked">
                    <Checkbox>Bifacial</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    )

    const inversorComponente = (
        <>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item
                        name={'potencia'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com a potência',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Potência'}
                            addonAfter={'kW'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Form.List name="configuracoesMPPT">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }, index) => (
                            <div key={key}>
                                <Space>
                                    <b>Configuração {index + 1}</b>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            onClick={() => remove(name)}
                                        />
                                    ) : null}
                                </Space>

                                <Row gutter={16}>
                                    <Col
                                        xs={{ span: 24 }}
                                        lg={{ span: 12 }}
                                        xl={{ span: 12 }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'numeroMPPTS']}
                                            label={'Número de MPPTs'}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 24 }}
                                        lg={{ span: 12 }}
                                        xl={{ span: 12 }}
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'stringsPorMPPT']}
                                            label="Strings por MPPT"
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                precision={0}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <Row gutter={16}>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 12 }}
                                xl={{ span: 12 }}
                            >
                                <Button
                                    type={'dashed'}
                                    icon={<PlusCircleOutlined />}
                                    style={{ width: '100%' }}
                                    onClick={() => add()}
                                >
                                    Adicionar configuração MPPTs
                                </Button>
                            </Col>
                        </Row>
                    </>
                )}
            </Form.List>
        </>
    )

    const transformadorComponente = (
        <>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item
                        name={'potencia'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com a potência',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Potência'}
                            addonAfter={'kVA'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item
                        name={'tensaoPrimaria'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a tensão primária',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Tensão primária'}
                            addonAfter={'V'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item
                        name={'tensaoSecundaria'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a tensão secundária',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Tensão secundária'}
                            addonAfter={'V'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                    <Form.Item
                        name={'numeroFases'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com o número de fases',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{ width: '100%' }}
                            placeholder={'Número de fases'}
                            precision={0}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )

    return (
        <Form form={form}>
            <Drawer
                className={'button-drawer'}
                style={{ position: 'relative' }}
                width={width < 640 ? '100%' : 640}
                placement="right"
                closable={true}
                onClose={(e) => {
                    props.setOpen(false)
                    navigate(-1)
                }}
                open={props.open}
                title={'Novo ' + props.tipoComponente}
                extra={
                    <Space>
                        <Button
                            size={'small'}
                            onClick={(e) => {
                                props.setOpen(false)
                                navigate(-1)
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            size={'small'}
                            type={'primary'}
                            onClick={(e) => {
                                if (
                                    form
                                        .getFieldsError()
                                        .filter((e) => e.errors.length > 0)
                                        .length === 0
                                ) {
                                    onFinish(form.getFieldsValue())
                                } else {
                                    messageApi.warning(
                                        'Por favor preencha os campos obrigatórios',
                                        8
                                    )
                                }
                            }}
                        >
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name={'fabricante'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor entre com o fabricante',
                                },
                            ]}
                            normalize={(value) => value.toUpperCase()}
                        >
                            <FabricanteWithAddField tipoComponente={'painel'} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                        <Form.Item
                            name={'modelo'}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor entre com o modelo do componente',
                                },
                            ]}
                            normalize={(value) => value.toUpperCase()}
                        >
                            <Input placeholder={'Modelo'} />
                        </Form.Item>
                    </Col>
                </Row>
                {props.tipoComponente === 'painel' && painelComponente}
                {props.tipoComponente === 'inversor' && inversorComponente}
                {props.tipoComponente === 'transformador' &&
                    transformadorComponente}
            </Drawer>
            {contextHolder}
        </Form>
    )
}

export default NovoComponenteInspecaoDrawer
