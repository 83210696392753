import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Checkbox,
    Col,
    Divider,
    Drawer,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Select,
    Space,
    Spin,
    Typography,
} from 'antd'
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleFilled,
    MinusCircleOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons'
import { gold } from '@ant-design/colors'
import useWindowDimensions from '../../utils/useWindowDimensions'
import { AccountContext } from '../../utils/Auth'
import FabricanteWithAddField from '../../components/FabricanteWithAddField'
import { useLocalDb } from '../../utils/useLocalDb'
import usePWAInstall from '../../utils/usePWAInstall'
import { useLocation, useNavigate } from 'react-router-dom'

interface VisualizarComponenteDrawerProps {
    open: boolean
    setOpen: (open: boolean) => void
    id: number
    tipoComponente: 'inversor' | 'painel' | 'transformador'
    setComponenteModificado: (modificado: boolean) => void
    readOnlyMode?: boolean
    modelo?: string
}

const VisualizarComponenteDrawer = (props: VisualizarComponenteDrawerProps) => {
    const [isOwner, setIsOwner] = useState(true)
    const [data, setData] = useState<any>(undefined)
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [usuarios, setUsuarios] = useState<any[]>([])
    const [form] = Form.useForm()
    const { width } = useWindowDimensions()
    const { authenticatedFetch, getEmailUsuario } = useContext(AccountContext)
    const {
        getComponenteFromLocalDb,
        getComponentesWithPaginationFromLocalDb,
        isOnline,
    } = useLocalDb()
    const { isInstalled } = usePWAInstall()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (
            props.open &&
            location.pathname !==
                '/app/componentes/' + props.tipoComponente + '/' + props.id &&
            !location.pathname.includes('visualizarComponente')
        ) {
            props.setOpen(false)
        }
    }, [props.open, location])

    useEffect(() => {
        if (props.id !== -1 && !isEditMode) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP +
                    '/componentes/' +
                    props.tipoComponente +
                    '?id=' +
                    props.id,
                'GET'
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any) => {
                            setData(data)
                            setIsOwner(
                                data?.controlador?.email === getEmailUsuario()
                            )
                        })
                    }
                })
                .catch(() => {
                    if (isInstalled) {
                        getComponenteFromLocalDb(
                            props.tipoComponente,
                            props.id
                        ).then((data) => setData(data))
                    }
                })
        }
    }, [props.id, props.tipoComponente, isEditMode])

    useEffect(() => {
        if (props.modelo !== undefined && props.modelo !== '') {
            let requestUrl =
                process.env.REACT_APP_SERVER_IP +
                '/componentes/' +
                props.tipoComponente +
                `/listar?pagina=0`

            if (props.modelo.length > 0) {
                requestUrl += `&modelo=${props.modelo}`
            }

            if (props.tipoComponente !== undefined) {
                authenticatedFetch(requestUrl, 'GET')
                    .then((res: { ok: any; json: () => Promise<any[]> }) => {
                        if (res.ok) {
                            res.json().then((data: any) => {
                                setData(data.content[0])
                            })
                        }
                    })
                    .catch((e: any) => {
                        if (isInstalled) {
                            getComponentesWithPaginationFromLocalDb(
                                props.tipoComponente,
                                props.modelo ? props.modelo : '',
                                undefined,
                                8,
                                0
                            ).then((data: any) => {
                                setData(data.content[0])
                            })
                        } else {
                            message.error({
                                key: 'componentesFetchError',
                                content: 'Erro ao tentar contactar o servidor',
                                duration: 8,
                            })
                        }
                    })
            }
        }
    }, [props.modelo, props.tipoComponente])

    useEffect(() => {
        form.resetFields()
        if (isEditMode) {
            form.setFieldsValue({
                modelo: data?.modelo,
                fabricante: data?.fabricante,
                potencia: data?.potencia,
                controlador: data?.controlador,
            })
            switch (props.tipoComponente) {
                case 'inversor':
                    if (data?.configuracoesMPPT.length > 0) {
                        form.setFieldsValue({
                            configuracoesMPPT: data?.configuracoesMPPT,
                        })
                    }
                    break
                case 'painel':
                    form.setFieldsValue({
                        bifacial: data?.bifacial,
                    })
                    break
                case 'transformador':
                    form.setFieldsValue({
                        tensaoPrimaria: data?.tensaoPrimaria,
                        tensaoSecundaria: data?.tensaoSecundaria,
                    })
                    break
                default:
                    break
            }
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usuario/listarUsuarios',
                'GET'
            )
                .then((res: { ok: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setUsuarios(data)
                        })
                    }
                })
                .catch(() => {
                    message.error('Erro ao tentar contactar o servidor', 8)
                })
        }
    }, [isEditMode])

    const getDataModificacao = () => {
        if (data?.ultimaModificacao) {
            return new Date(data?.ultimaModificacao).toLocaleString('pt-BR', {
                timeZone: 'America/Sao_Paulo',
                day: 'numeric',
                month: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
            })
        }
    }

    const onFinishModificacao = (values: any) => {
        let body = values

        // Envia somente o email como identificador
        delete body.controlador.nome

        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/componentes/' +
                props.tipoComponente +
                '?id=' +
                props.id,
            'PATCH',
            JSON.stringify(body)
        )
            .then((res: { ok: any; json: () => Promise<any[]> }) => {
                if (res.ok) {
                    message.success('Componente modificado com sucesso!', 8)
                    setIsEditMode(false)
                    props.setComponenteModificado(true)
                } else {
                    message.error('Erro ao modificar componente', 8)
                }
            })
            .catch(() => {
                message.error('Erro ao tentar contactar o servidor', 8)
            })
    }

    const caracteristicasInversor = (
        <>
            <Typography.Title level={5}>
                Características específicas do inversor
            </Typography.Title>
            <Row>
                <Col span={24}>
                    <Typography.Text>Potência: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.potencia}kW
                    </Typography.Text>
                </Col>
            </Row>
            {data?.configuracoesMPPT?.length > 0 &&
            data?.configuracoesMPPT[0]?.stringsPorMPPT !== null &&
            data?.configuracoesMPPT[0]?.numeroMPPTS !== null ? (
                data?.configuracoesMPPT?.map(
                    (configuracao: any, index: number) => (
                        <div style={{ marginTop: 16 }} key={configuracao.id}>
                            <Typography.Text strong style={{ marginTop: 16 }}>
                                Configuração {index + 1}
                            </Typography.Text>
                            <Row>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                    <Typography.Text>
                                        Número de MPPTs:{' '}
                                    </Typography.Text>
                                    <Typography.Text type={'secondary'}>
                                        {configuracao.numeroMPPTS}
                                    </Typography.Text>
                                </Col>
                                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                    <Typography.Text>
                                        Strings por MPPT:{' '}
                                    </Typography.Text>
                                    <Typography.Text type={'secondary'}>
                                        {configuracao.stringsPorMPPT}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </div>
                    )
                )
            ) : (
                <Typography.Text type={'secondary'}>
                    <br />
                    Não há configurações de MPPT cadastradas
                </Typography.Text>
            )}
        </>
    )

    const caracteristicasPainel = (
        <>
            <Typography.Title level={5}>
                Características específicas do painel
            </Typography.Title>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Potência: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.potencia}Wp
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Bifacial: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.bifacial ? 'Sim' : 'Não'}
                    </Typography.Text>
                </Col>
            </Row>
        </>
    )

    const caracteristicasTransformador = (
        <>
            <Typography.Title level={5}>
                Características específicas do transformador
            </Typography.Title>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Potência: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.potencia}kVA
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Número de fases: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.numeroFases}
                    </Typography.Text>
                </Col>
            </Row>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Tensão primária: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.tensaoPrimaria}V
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Tensão secundária: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.tensaoSecundaria}V
                    </Typography.Text>
                </Col>
            </Row>
        </>
    )

    const caracteristicasInversorEdit = (
        <>
            <Form
                layout={'vertical'}
                form={form}
                initialValues={{
                    configuracoesMPPT: [
                        { numeroMPPTS: null, stringsPorMPPT: null },
                    ],
                }}
            >
                <Typography.Title level={5}>
                    Características específicas do inversor
                </Typography.Title>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name={'potencia'}
                            label={'Potência'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor entre com a potência',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                addonAfter={'kW'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="configuracoesMPPT">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(
                                ({ key, name, ...restField }, index) => (
                                    <div key={key}>
                                        <Space>
                                            <b>Configuração {index + 1}</b>
                                            {fields.length > 1 ? (
                                                <MinusCircleOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            ) : null}
                                        </Space>

                                        <Row gutter={16}>
                                            <Col
                                                xs={{ span: 24 }}
                                                lg={{ span: 12 }}
                                                xl={{ span: 12 }}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'numeroMPPTS']}
                                                    label={'Número de MPPTs'}
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col
                                                xs={{ span: 24 }}
                                                lg={{ span: 12 }}
                                                xl={{ span: 12 }}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        name,
                                                        'stringsPorMPPT',
                                                    ]}
                                                    label="Strings por MPPT"
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Button
                                        type={'dashed'}
                                        icon={<PlusCircleOutlined />}
                                        style={{ width: '100%' }}
                                        onClick={() => add()}
                                    >
                                        Adicionar outra configuração
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Form.List>
            </Form>
        </>
    )

    const caracteristicasPainelEdit = (
        <>
            <Form layout={'vertical'} form={form}>
                <Typography.Title level={5}>
                    Características específicas do painel
                </Typography.Title>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name={'potencia'}
                            label={'Potência'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor entre com a potência',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                addonAfter={'Wp'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={'bifacial'}
                            label={' '}
                            valuePropName={'checked'}
                        >
                            <Checkbox>Bifacial</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )

    const caracteristicasTransformadorEdit = (
        <>
            <Form layout={'vertical'} form={form}>
                <Typography.Title level={5}>
                    Características específicas do transformador
                </Typography.Title>

                <Row gutter={16}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={'potencia'}
                            label={'Potência'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor entre com a potência',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                addonAfter={'kVA'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={'tensaoPrimaria'}
                            label={'Tensão primária'}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor entre com a tensão primária',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                addonAfter={'V'}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={'tensaoSecundaria'}
                            label={'Tensão secundária'}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor entre com a tensão secundária',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{ width: '100%' }}
                                addonAfter={'V'}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )

    const caracteristicasGerais = (
        <>
            {data?.controlador && (
                <>
                    <Typography.Title level={5}>Histórico</Typography.Title>
                    <Row>
                        <Col span={24}>
                            <Typography.Text>Controlado por: </Typography.Text>
                            <Typography.Text type={'secondary'}>
                                {data?.controlador?.nome} (
                                {data?.controlador?.email})
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Typography.Text>
                                Última modificação:{' '}
                            </Typography.Text>
                            <Typography.Text type={'secondary'}>
                                {data?.ultimaModificacao &&
                                    getDataModificacao()}
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Divider />
                </>
            )}

            <Typography.Title level={5} style={{ marginTop: 0 }}>
                Características gerais
            </Typography.Title>
            <Row>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Modelo: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.modelo}
                    </Typography.Text>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                    <Typography.Text>Fabricante: </Typography.Text>
                    <Typography.Text type={'secondary'}>
                        {data?.fabricante}
                    </Typography.Text>
                </Col>
            </Row>
        </>
    )

    // @ts-ignore
    const caracteristicasGeraisEdit = (
        <>
            <Form layout={'vertical'} form={form}>
                <Typography.Title level={5} style={{ marginTop: 0 }}>
                    Histórico
                </Typography.Title>
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={['controlador', 'email']}
                            label={'Controlado por'}
                        >
                            <Select
                                getPopupContainer={() =>
                                    document.getElementsByClassName(
                                        'ant-drawer-body'
                                    )[0] as HTMLElement
                                }
                                allowClear={true}
                                style={{ width: '100%' }}
                                showSearch
                                options={usuarios?.map((e) => ({
                                    label: (
                                        <>
                                            <Typography.Text>
                                                {e.nome}
                                            </Typography.Text>
                                            <Typography.Text type={'secondary'}>
                                                {' ('}
                                                {e.email}
                                                {')'}
                                            </Typography.Text>
                                        </>
                                    ),
                                    value: e.email,
                                }))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                        <Typography.Text>Última modificação: </Typography.Text>
                        <Typography.Text type={'secondary'}>
                            {data?.ultimaModificacao && getDataModificacao()}
                        </Typography.Text>
                    </Col>
                </Row>
                <Divider />
                <Typography.Title level={5}>
                    Características gerais
                </Typography.Title>
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={'modelo'}
                            label={'Modelo'}
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor entre com o modelo do componente',
                                },
                            ]}
                        >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                        <Form.Item
                            name={'fabricante'}
                            label={'Fabricante'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor entre com o fabricante',
                                },
                            ]}
                        >
                            <FabricanteWithAddField
                                isInsideDrawer={true}
                                tipoComponente={props.tipoComponente}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )

    const renderCaracteristicasEspecificas = () => {
        switch (props.tipoComponente) {
            case 'inversor':
                return caracteristicasInversor
            case 'painel':
                return caracteristicasPainel
            case 'transformador':
                return caracteristicasTransformador
            default:
                return null
        }
    }

    const renderCaracteristicasEspecificasEdit = () => {
        switch (props.tipoComponente) {
            case 'inversor':
                return caracteristicasInversorEdit
            case 'painel':
                return caracteristicasPainelEdit
            case 'transformador':
                return caracteristicasTransformadorEdit
            default:
                return null
        }
    }

    const handleRemoverComponente = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/componentes/' +
                props.tipoComponente +
                '?id=' +
                props.id,
            'DELETE'
        )
            .then(
                (res: { ok: any; status: any; json: () => Promise<any[]> }) => {
                    if (res.ok) {
                        message.success('Componente removido com sucesso!', 8)
                        setIsDeleteModalOpen(false)
                        setIsEditMode(false)
                        props.setComponenteModificado(true)
                        props.setOpen(false)
                    } else if (res.status === 400) {
                        message.error(
                            'Erro ao remover componente. Somente é possível removê-lo se não existir uma inspeção associada à ele.',
                            8
                        )
                    }
                }
            )
            .catch(() => {
                message.error('Erro ao tentar contactar o servidor', 8)
            })
    }

    const confirmarRemovacaoModal = (
        <Modal
            title={
                <>
                    <ExclamationCircleFilled style={{ color: gold.primary }} />{' '}
                    Deseja realmente remover o componente?
                </>
            }
            open={isDeleteModalOpen}
            onOk={() => {
                handleRemoverComponente()
            }}
            onCancel={(e) => {
                setIsDeleteModalOpen(false)
            }}
            okText={'Remover'}
        >
            O componente somente será removido se não houver nenhuma usina
            utilizando-o.
            <br />
            Essa ação não pode ser desfeita.
        </Modal>
    )

    const drawerTitle = () => {
        switch (props.tipoComponente) {
            case 'inversor':
                return 'Inversor'
            case 'painel':
                return 'Painel'
            case 'transformador':
                return 'Transformador'
            default:
                return ''
        }
    }

    const renderDrawerContent = () => {
        return (
            <>
                {isEditMode ? caracteristicasGeraisEdit : caracteristicasGerais}
                <Divider />
                {isEditMode
                    ? renderCaracteristicasEspecificasEdit()
                    : renderCaracteristicasEspecificas()}
                <Divider />
            </>
        )
    }

    const renderDrawerHeaderExtras = () => {
        if (props.readOnlyMode !== undefined && props.readOnlyMode === true) {
            return <></>
        } else {
            return isEditMode ? (
                <Space>
                    <Button
                        size={'small'}
                        onClick={(e) => setIsEditMode(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size={'small'}
                        type={'primary'}
                        onClick={(e) => {
                            if (
                                form
                                    .getFieldsError()
                                    .filter((e) => e.errors.length > 0)
                                    .length === 0
                            ) {
                                onFinishModificacao(form.getFieldsValue())
                            } else {
                                message.warning(
                                    'Por favor preencha os campos obrigatórios',
                                    8
                                )
                            }
                        }}
                    >
                        Salvar
                    </Button>
                </Space>
            ) : (
                <Space>
                    <Button
                        size={'small'}
                        disabled={!isOwner || !isOnline}
                        icon={<DeleteOutlined />}
                        onClick={(e) => setIsDeleteModalOpen(true)}
                    >
                        Remover
                    </Button>
                    <Button
                        size={'small'}
                        disabled={!isOwner || !isOnline}
                        onClick={(e) => setIsEditMode(true)}
                        icon={<EditOutlined />}
                    >
                        Editar
                    </Button>
                </Space>
            )
        }
    }

    return (
        <>
            <Drawer
                className={'button-drawer'}
                style={{ position: 'relative' }}
                width={width < 640 ? '100%' : 640}
                placement="right"
                closable={true}
                onClose={(e) => {
                    props.setOpen(false)
                    setIsEditMode(false)
                    navigate(-1)
                }}
                open={props.open}
                title={drawerTitle()}
                extra={renderDrawerHeaderExtras()}
            >
                {data === undefined ? (
                    <Spin style={{ width: '100%' }} />
                ) : (
                    renderDrawerContent()
                )}
            </Drawer>
            {confirmarRemovacaoModal}
        </>
    )
}

export default VisualizarComponenteDrawer
