import React, { useContext, useEffect, useState } from 'react'
import {
    App,
    Button,
    Col,
    DatePicker,
    Modal,
    Row,
    Select,
    Space,
    Table,
    Typography,
} from 'antd'
import { ColumnsType } from 'antd/es/table'
import {
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
    WarningOutlined,
} from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { AccountContext } from '../../../utils/Auth'
import { useLocalDb } from '../../../utils/useLocalDb'
import VisualizarInspecaoDrawer from './Inspecao/VisualizarInspecaoDrawer'
import usePWAInstall from '../../../utils/usePWAInstall'
import UltimaSincronizacao from '../../../components/UltimaSincronizacao'

type Props = {
    idUsina: string | undefined
    controlador: { id: number; nome: string; email: string }
    usinaData: any
}

const Inspecoes = (props: Props) => {
    const { message } = App.useApp()
    const [inspetores, setInspetores] = useState<any[]>([])
    const {
        authenticatedFetch,
        getEmailUsuario,
        getTipoUsuario,
        getNomeUsuario,
    } = useContext(AccountContext)
    const [data, setData] = useState<any>()
    const [dataInspecao, setDataInspecao] = useState<any>()
    const [inspetor, setInspetor] = useState<string>()
    const [pagina, setPagina] = useState<number>(0)
    const [inspecaoId, setInspecaoId] = useState<number>(-1)
    const [inspecaoDrawerOpen, setInspecaoDrawerOpen] = useState<boolean>(false)
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<number>(8)
    const [totalElementos, setTotalElementos] = useState<number>(0)
    const { isOnline, getInspecoesWithPaginationFromLocalDb, syncInspecoes } =
        useLocalDb()
    const { isInstalled } = usePWAInstall()
    const navigate = useNavigate()
    const { id } = useParams()
    const [tipoUsina, setTipoUsina] = useState<string>('')

    useEffect(() => {
        getEquipe()
    }, [])

    useEffect(() => {
        getInspecoes()
    }, [inspetor, dataInspecao, pagina, isInstalled, isOnline])

    useEffect(() => {
        if (id !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id,
                'GET'
            )
                .then(
                    (res: {
                        ok: any
                        status: number
                        json: () => Promise<any[]>
                    }) => {
                        if (res.ok) {
                            res.json().then((data: any) => {
                                if (data.tipoUsina !== undefined) {
                                    setTipoUsina(data.tipoUsina)
                                }
                            })
                        } else if (res.status === 404) {
                            message.error({
                                key: 'usinaFetchError',
                                content: 'Usina não encontrada',
                                duration: 6,
                            })
                        }
                    }
                )
                .catch(() => {
                    message.error({
                        key: 'usinaFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 6,
                    })
                })
        }
    }, [id])

    const getInspecoes = () => {
        let requestUrl =
            process.env.REACT_APP_SERVER_IP +
            `/inspecoes/listarInspecoes?idUsina=${id}&pagina=${pagina}`

        if (dataInspecao) {
            requestUrl += `&dataComMes=${dataInspecao.toISOString()}`
        }
        if (inspetor && inspetor.length > 0) {
            requestUrl += `&emailInspetor=${inspetor}`
        }

        authenticatedFetch(requestUrl, 'GET')
            .then((res: { ok: any; json: () => Promise<any[]> }) => {
                if (res.ok) {
                    res.json().then((data: any) => {
                        setData(data.content)
                        setTotalElementos(data.totalElements)
                        setPageSize(data.size)
                    })
                }
            })
            .catch((e: any) => {
                if (isInstalled && !isOnline && typeof id === 'string') {
                    getInspecoesWithPaginationFromLocalDb(
                        parseInt(id),
                        8,
                        pagina
                    ).then((data: any) => {
                        const inspetor = {
                            nome: getNomeUsuario(),
                            email: getEmailUsuario(),
                        }
                        data.content.forEach((inspecao: any) => {
                            inspecao['inspetor'] = inspetor
                        })
                        setData(data.content)
                        setTotalElementos(data.totalElements)
                        setPageSize(8)
                    })
                } else if (isOnline) {
                    message.error({
                        key: 'inspecoesFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
                }
            })
    }

    const getEquipe = () => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP +
                '/usinas/equipe?idUsina=' +
                props.idUsina,
            'GET'
        )
            .then(
                (res: {
                    ok: any
                    status: number
                    json: () => Promise<any[]>
                }) => {
                    if (res.ok) {
                        res.json().then((data: any[]) => {
                            setInspetores([props.controlador, ...data])
                        })
                    } else if (res.status === 404) {
                        message.error({
                            key: 'equipeFetchError',
                            content: 'Equipe não encontrada',
                            duration: 8,
                        })
                    }
                }
            )
            .catch(() => {
                if (isOnline) {
                    message.error({
                        key: 'equipeFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
                }
            })
    }

    const deleteInspecao = (id: number) => {
        authenticatedFetch(
            process.env.REACT_APP_SERVER_IP + `/inspecoes?id=${id}`,
            'DELETE'
        )
            .then((res: { ok: any; status: number }) => {
                if (res.ok) {
                    message.success({
                        key: 'inspecaoDeleteSuccess',
                        content: 'Inspeção deletada com sucesso',
                        duration: 6,
                    })
                    getInspecoes()
                    setShowDeleteModal(false)
                } else {
                    message.error({
                        key: 'inspecaoDeleteError',
                        content: 'Erro ao tentar deletar inspeção',
                        duration: 6,
                    })
                }
            })
            .catch(() => {
                if (isOnline) {
                    message.error({
                        key: 'inspecaoDeleteError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 6,
                    })
                }
            })
    }

    const removerInspecaoModal = () => (
        <Modal
            title={
                <>
                    <WarningOutlined /> Remover inspeção
                </>
            }
            open={showDeleteModal}
            okText="Remover"
            cancelText="Cancelar"
            onCancel={(e) => {
                setShowDeleteModal(false)
            }}
            onOk={(e) => {
                deleteInspecao(inspecaoId)
            }}
            okButtonProps={{ danger: true }}
            zIndex={1001}
        >
            A remoção é permanente e não poderá ser desfeita. Deseja continuar?
        </Modal>
    )

    interface DataType {
        key: string
        id: number
        inspetor: { id: number; nome: string; email: string }
        formType: string
    }

    const columns: ColumnsType<DataType> = [
        {
            title: 'Data',
            dataIndex: 'dataCriacao',
            key: 'dataInspecao',
            ellipsis: true,
            render: (dataCriacao: any) => {
                return (
                    <>
                        {new Date(dataCriacao).toLocaleDateString('pt-BR', {
                            timeZone: 'America/Sao_Paulo',
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                        })}
                    </>
                )
            },
        },
        {
            title: 'Inspetor',
            dataIndex: 'inspetor',
            key: 'inspetor',
            responsive: ['lg'],
            render: (inspetor: any) => {
                return <>{inspetor.nome}</>
            },
        },
        {
            title: 'Ações',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                <Space size="middle">
                    {(record.inspetor.email === getEmailUsuario() ||
                        getTipoUsuario() === 'ADMIN') &&
                        isOnline && (
                            <Button
                                disabled={!isOnline}
                                shape={'circle'}
                                onClick={(e) => {
                                    setInspecaoId(record.id)
                                    setShowDeleteModal(true)
                                }}
                                icon={<DeleteOutlined />}
                            />
                        )}
                    {(record.inspetor.email === getEmailUsuario() ||
                        getTipoUsuario() === 'ADMIN') &&
                        isOnline && (
                            <Button
                                disabled={!isOnline}
                                shape={'circle'}
                                onClick={(e) => {
                                    navigate(
                                        'inspecao/' +
                                            record.id +
                                            '?formType=' +
                                            record.formType
                                    )
                                }}
                                icon={<EditOutlined />}
                            />
                        )}
                    <Button
                        disabled={!isOnline}
                        shape={'circle'}
                        onClick={(e) => {
                            setInspecaoId(record.id)
                            navigate('visualizarInspecao')
                            setInspecaoDrawerOpen(true)
                        }}
                        icon={<SearchOutlined />}
                    />
                </Space>
            ),
        },
    ]

    return (
        <>
            {removerInspecaoModal()}
            <VisualizarInspecaoDrawer
                idInspecao={inspecaoId}
                visualizarInspecaoDrawerOpen={inspecaoDrawerOpen}
                setVisualizarInspecaoDrawerOpen={setInspecaoDrawerOpen}
                tipoUsina={tipoUsina}
                usinaData={props.usinaData}
            />
            <Space direction={'vertical'} style={{ width: '100%' }}>
                <Row gutter={[16, 16]}>
                    <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                        <Select
                            disabled={!isOnline}
                            value={inspetor}
                            onChange={(value) => {
                                setInspetor(value)
                            }}
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Inspetor"
                            options={inspetores?.map((e) => ({
                                label: (
                                    <>
                                        <Typography.Text>
                                            {e.nome}
                                        </Typography.Text>
                                        <Typography.Text type={'secondary'}>
                                            {' ('}
                                            {e.email}
                                            {')'}
                                        </Typography.Text>
                                    </>
                                ),
                                value: e.email,
                            }))}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                        <DatePicker
                            disabled={!isOnline}
                            style={{ width: '100%' }}
                            inputReadOnly
                            format={'MM/YYYY'}
                            value={dataInspecao}
                            onChange={(e) => setDataInspecao(e)}
                            placeholder={'Data da inspeção'}
                            picker="month"
                        />
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}
                    pagination={{
                        total: totalElementos,
                        pageSize: pageSize,
                        current: pagina + 1,
                        onChange: (page, pageSize) => setPagina(page - 1),
                        showSizeChanger: false,
                    }}
                />
                {isInstalled && (
                    <UltimaSincronizacao
                        tabela={'inspecao'}
                        onSync={syncInspecoes}
                    />
                )}
            </Space>
        </>
    )
}

export default Inspecoes
