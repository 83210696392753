import React, { useContext, useEffect, useState } from 'react'
import {
    App,
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Steps,
} from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import usePWAInstall from '../../../utils/usePWAInstall'
import { AccountContext } from '../../../utils/Auth'
import { regionaisList } from '../Usinas'
import dayjs from 'dayjs'
import { useLocalDb } from '../../../utils/useLocalDb'
import { nanoid } from 'nanoid'

const NovaModificarUsina = () => {
    const { message } = App.useApp()
    const { isInstalled } = usePWAInstall()
    const { authenticatedFetch } = useContext(AccountContext)
    const { id } = useParams()
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const { addUsinaToLocalDb } = useLocalDb()

    useEffect(() => {
        if (id !== undefined) {
            authenticatedFetch(
                process.env.REACT_APP_SERVER_IP + '/usinas?idUsina=' + id,
                'GET'
            )
                .then(
                    (res: {
                        ok: any
                        status: number
                        json: () => Promise<any[]>
                    }) => {
                        if (res.ok) {
                            res.json().then((data: any) => {
                                let formattedData: any = data
                                delete formattedData['ultimaModificacao']
                                delete formattedData['dataCriacao']
                                formattedData['dataInstalacao'] = dayjs(
                                    data.dataInstalacao
                                )
                                formattedData['dataInicioOperacao'] = dayjs(
                                    data.dataInicioOperacao
                                )

                                form.setFieldsValue(formattedData)
                            })
                        } else if (res.status === 404) {
                            message.error({
                                key: 'usinaFetchError',
                                content: 'Usina não encontrada',
                                duration: 8,
                            })
                        }
                    }
                )
                .catch(() => {
                    message.error({
                        key: 'usinaFetchError',
                        content: 'Erro ao tentar contactar o servidor',
                        duration: 8,
                    })
                })
        }
    }, [id])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [currentStep])

    const handleStepChange = (value: number) => {
        if (value > currentStep) {
            form.validateFields()
                .then((values) => {
                    setCurrentStep(value)
                })
                .catch((error) => {})
        } else {
            setCurrentStep(value)
        }
    }

    const onFinish = () => {
        let values = form.getFieldsValue(true)
        let requestUrl = process.env.REACT_APP_SERVER_IP + '/usinas'

        if (id !== undefined) {
            requestUrl += '?idUsina=' + id
        }

        authenticatedFetch(
            requestUrl,
            id ? 'PATCH' : 'POST',
            JSON.stringify(values)
        )
            .then((res: { status: number }) => {
                if (res.status === 201) {
                    message.success('Usina cadastrada com sucesso!', 8)
                    navigate(-1)
                } else if (res.status === 200) {
                    message.success('Usina atualizada com sucesso!', 8)
                    navigate(-1)
                } else {
                    message.error('Erro ao tentar cadastrar a usina', 8)
                }
            })
            .catch(() => {
                if (isInstalled) {
                    if (id === undefined) {
                        let newValues = values
                        newValues['idOffline'] = nanoid()
                        let stringifiedValues = JSON.stringify(newValues)

                        addUsinaToLocalDb(JSON.parse(stringifiedValues)).then(
                            (e) => {
                                message.success(
                                    'Usina cadastrada com sucesso!',
                                    8
                                )
                                navigate('/app/usinas')
                            }
                        )
                    }
                    navigate(-1)
                } else {
                    message.error('Erro ao tentar contactar o servidor', 8)
                }
            })
    }

    const step0 = (
        <>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Proprietário'}
                        name={'proprietario'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com o nome do proprietário',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Tipo de produção'}
                        name={'tipoProducao'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com o tipo de produção da usina',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Telefone 1'}
                        name={'telefone1'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com o telefone',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item label={'Telefone 2/E-mail'} name={'telefone2'}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Cidade'}
                        name={'cidade'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a cidade da usina',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Endereço'}
                        name={'endereco'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com o endereço da usina',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Latitude'}
                        name={'latitude'}
                        rules={[
                            {
                                pattern: /^[-]?\d*\.?\d*$/,
                                required: true,
                                message:
                                    'Por favor entre com a latitude (ex. -23.1234)',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Longitude'}
                        name={'longitude'}
                        rules={[
                            {
                                pattern: /^[-]?\d*\.?\d*$/,
                                required: true,
                                message:
                                    'Por favor entre com a longitude (ex. -23.1234)',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    lg={{ span: 8, offset: 16 }}
                    xl={{ span: 6, offset: 18 }}
                >
                    <Form.Item>
                        <Button
                            style={{ width: '100%' }}
                            type={'primary'}
                            onClick={(e) => handleStepChange(currentStep + 1)}
                        >
                            Próximo
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )

    const step1 = (
        <>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Data de instalação'}
                        name={'dataInstalacao'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a data de instalação',
                            },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Empresa instaladora'}
                        name={'empresaInstaladora'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a empresa instaladora',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Data de início da operação'}
                        name={'dataInicioOperacao'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a data de início da operação',
                            },
                        ]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Tipo de usina'}
                        name={'tipoUsina'}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor entre com o tipo de usina',
                            },
                        ]}
                    >
                        <Select
                            allowClear={true}
                            options={[
                                { value: 'SOLO', label: 'Solo' },
                                { value: 'COBERTURA', label: 'Cobertura' },
                            ]}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}></Col>
            </Row>
            <Row gutter={8}>
                <Col xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                    <Form.Item
                        label={'Regional do IDR'}
                        name={'regionalIdr'}
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor entre com a regional do IDR da usina',
                            },
                        ]}
                    >
                        <Select options={regionaisList} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col
                    xs={{ span: 24, offset: 0 }}
                    lg={{ span: 8, offset: 16 }}
                    xl={{ span: 6, offset: 18 }}
                >
                    <Form.Item>
                        <Button
                            style={{ width: '100%' }}
                            type={'primary'}
                            htmlType={'submit'}
                        >
                            {id !== undefined ? 'Salvar' : 'Adicionar'}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )

    return (
        <Card title={id !== undefined ? 'Modificar usina' : 'Nova usina'}>
            <Form form={form} layout={'vertical'} onFinish={onFinish}>
                <Steps
                    current={currentStep}
                    size={'small'}
                    direction={'vertical'}
                    onChange={handleStepChange}
                    items={[
                        {
                            title: 'Dados da usina',
                            description: currentStep === 0 ? step0 : '',
                        },
                        {
                            title: 'Dados do IDR',
                            description: currentStep === 1 ? step1 : '',
                        },
                    ]}
                />
            </Form>
        </Card>
    )
}

export default NovaModificarUsina
