// db.ts
import Dexie, { Table } from 'dexie'

export interface Pending {
    id?: number
    method: string
    url: string
    body: string
}

export interface LastSync {
    id?: number
    tableName: string
    lastSync: Date
}

export interface Inversor {
    id?: number
    potencia: number
    fabricante: string
    modelo: string
    ultimaModificacao: Date
    deletado: number | boolean
}

export interface Painel {
    id?: number
    potencia: number
    isBifacial: boolean
    fabricante: string
    modelo: string
    ultimaModificacao: Date
    deletado: number | boolean
}

export interface Transformador {
    id?: number
    potencia: number
    tensaoPrimaria: number
    tensaoSecundaria: number
    fabricante: string
    modelo: string
    ultimaModificacao: Date
    deletado: number | boolean
}

export interface Usina {
    id?: number
    idOffline?: string
    nome: string
    regionalIdr: string
    cidade: string
    endereco: string
    latitude: string
    longitude: string
    proprietario: string
    telefone1: string
    telefone2: string
    tipoProducao: string
    tipoUsina: string
    empresaInstaladora: string
    beneficiosApontadosPeloProprietario: string
    dataInicioOperacao: string
    dataInstalacao: string
    ultimaModificacao: Date | string
    deletado: number | boolean
}

export interface Inspecao {
    id?: number
    idUsinaOffline: string | number
    ultimaModificacao: Date | string
    dataCriacao: string
    fotos: any[]
}

export class MySubClassedDexie extends Dexie {
    pending!: Table<Pending>
    lastSync!: Table<LastSync>
    inversor!: Table<Inversor>
    painel!: Table<Painel>
    transformador!: Table<Transformador>
    usina!: Table<Usina>
    inspecao!: Table<Inspecao>

    constructor() {
        super('localDatabaseNapiSolar')
        this.version(1).stores({
            pending: '++id',
            lastSync: '++id, &tableName',
            inversor: '++id, fabricante, modelo, ultimaModificacao, deletado',
            painel: '++id, fabricante, modelo, ultimaModificacao, deletado',
            transformador:
                '++id, fabricante, modelo, ultimaModificacao, deletado',
            usina: '++id, idOffline, nome, ultimaModificacao, regionalIdr, cidade',
            inspecao: '++id, idUsinaOffline, ultimaModificacao',
        })
    }
}

export const db = new MySubClassedDexie()
