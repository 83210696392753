import React from 'react'
import { Button, Col, Form, Row } from 'antd'
import QuestionWrapper from './QuestionWrapper'
import questions from './Questions'

type Props = {
    handleStepChange: (value: number) => void
    currentStep: number
    formInstance: any
    formType: string
}

const FormStep3 = (props: Props) => {
    return (
        <>
            <Row>
                <Col md={24} lg={18} xl={14} xxl={10}>
                    {(props.formType === 'PROD' || 'EXT') &&
                        'Não há itens nessa seção para esse nível de formulário'}
                    <QuestionWrapper
                        question={questions.q30}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={questions.q31}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={questions.q32}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={questions.q33}
                        formType={props.formType}
                    />
                    <QuestionWrapper
                        question={questions.q34}
                        formType={props.formType}
                    />

                    <Row>
                        <Col
                            xs={{ span: 24, offset: 0 }}
                            lg={{ span: 8, offset: 16 }}
                            xl={{ span: 6, offset: 18 }}
                        >
                            <Form.Item>
                                <Button
                                    style={{ width: '100%', marginTop: 16 }}
                                    type={'primary'}
                                    onClick={(e) =>
                                        props.handleStepChange(
                                            props.currentStep + 1
                                        )
                                    }
                                >
                                    Próximo
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default FormStep3
